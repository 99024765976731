<template>
    <el-row class="item">
        <el-col :span="24"><div class="info"><span>商品金额</span><span>￥{{goodsListData.originalAmount}}元</span></div></el-col>
        <el-col :span="24"><div class="info"><span>单品优惠券</span><span>￥-{{goodsListData.couponDetailAmount}}元</span></div></el-col>

        <el-col :span="24"><div class="info"><span>未购优惠券</span><span>￥-{{goodsListData.noBuyCouponAmount}}元</span></div></el-col>
        <el-col :span="24"><div class="info"><span>品类/多品优惠券:</span><span>￥{{goodsListData.specCouponAmount>0?-goodsListData.specCouponAmount:'-0'}}元</span></div></el-col>
        <el-col :span="24"><div class="info"><span>订单优惠券</span><span>￥{{goodsListData.couponAmount>0?-goodsListData.couponAmount:0}}元</span></div></el-col>
        <el-col :span="24"><div class="info"><span>促销折扣</span><span>￥-{{goodsListData.promotionAmount}}元</span></div></el-col>
        <el-col :span="24"><div class="info"><span>运费</span><span>￥{{goodsListData.freight}}元</span></div></el-col>
    </el-row>
</template>

<script>

export default {
  name:"DiscountFree",
  data(){
      return{
        goodsListData:''
      }
        
  },
   props:{
    goodsList:{
       type:Object
    }
  },
  computed: {
   
  },
  methods: {
   
  },
  mounted() {
     this.goodsListData=this.goodsList
  },
  watch:{
    "goodsList":function(newVal) {
        this.goodsListData=newVal
    }
  },
};
</script>



<style lang="less" scoped>
  @import '~style/index.less';
  .item {
      // padding-bottom: 10px;
      background: #fff;
      // margin-bottom: 10px;
      line-height: 20px;
      font-weight: normal;
      color: #333;
      font-size: 12px;
  }
 .info {
   text-align: right;
    padding: 0 20px;

   &>span:nth-child(1){
       text-align: right;
   }
   &>span:nth-child(2){
       padding-left: 20px;
       text-align: right;
       display: inline-block;
       width: 80px;
   }
  
 }

 
</style>